export const actionTypes = {
  GET_PROJECTMANAGEMENT_SUCCESS: 'GET_PROJECTMANAGEMENT_SUCCESS',
  GET_PROJECTMANAGEMENT_FAILURE: 'GET_PROJECTMANAGEMENT_FAILURE',
  GET_PROJECTMANAGEMENT_LOADER: 'GET_PROJECTMANAGEMENT_LOADER',

  CREATE_PROJECTMANAGEMENT_INFO_SUCCESS: 'CREATE_PROJECTMANAGEMENT_INFO_SUCCESS',
  CREATE_PROJECTMANAGEMENT_INFO_FAILURE: 'CREATE_PROJECTMANAGEMENT_INFO_FAILURE',
  CREATE_PROJECTMANAGEMENT_INFO_LOADER: 'CREATE_PROJECTMANAGEMENT_INFO_LOADER',

  SAVEORCREATE_PROJECT_SUCCESS: 'SAVEORCREATE_PROJECT_SUCCESS',
  SAVEORCREATE_PROJECT_FAILURE: 'SAVEORCREATE_PROJECT_FAILURE',
  SAVEORCREATE_PROJECT_LOADER: 'SAVEORCREATE_PROJECT_LOADER',

  OPEN_PM_CASE_SUCCESS: 'OPEN_PM_CASE_SUCCESS',
  OPEN_PM_CASE_FAILURE: 'OPEN_PM_CASE_FAILURE',
  OPEN_PM_CASE_LOADER: 'OPEN_PM_CASE_LOADER',

  RESET_PM_DATA_NAV: 'RESET_PM_DATA_NAV',

  UPDATE_PM_DATA: 'UPDATE_PM_DATA',

  GET_MY_PROJECTS_SUCCESS: 'GET_MY_PROJECTS_SUCCESS',
  GET_MY_PROJECTS_FAILURE: 'GET_MY_PROJECTS_FAILURE',
  GET_MY_PROJECTS_LOADER: 'GET_MY_PROJECTS_LOADER',

  APPROVE_OR_REJECT_PM_CASE_SUCCESS: 'APPROVE_OR_REJECT_PM_CASE_SUCCESS',
  APPROVE_OR_REJECT_PM_CASE_FAILURE: 'APPROVE_OR_REJECT_PM_CASE_FAILURE',
  APPROVE_OR_REJECT_PM_CASE_LOADER: 'APPROVE_OR_REJECT_PM_CASE_LOADER',

  NEED_RESOURCE_INFO_SUCCESS: 'NEED_RESOURCE_INFO_SUCCESS',
  NEED_RESOURCE_INFO_FAILURE: 'NEED_RESOURCE_INFO_FAILURE',
  NEED_RESOURCE_INFO_LOADER: 'NEED_RESOURCE_INFO_LOADER',

  CREATE_NEED_RESOURCE_INFO_SUCCESS: 'CREATE_NEED_RESOURCE_INFO_SUCCESS',
  CREATE_NEED_RESOURCE_INFO_FAILURE: 'CREATE_NEED_RESOURCE_INFO_FAILURE',
  CREATE_NEED_RESOURCE_INFO_LOADER: 'CREATE_NEED_RESOURCE_INFO_LOADER',

  WITHDRAW_PM_CASE_SUCCESS: 'WITHDRAW_PM_CASE_SUCCESS',
  WITHDRAW_PM_CASE_FAILURE: 'WITHDRAW_PM_CASE_FAILURE',
  WITHDRAW_PM_CASE_LOADER: 'WITHDRAW_PM_CASE_LOADER',

  HOLD_OR_RESERVE_SUCCESS: 'HOLD_OR_RESERVE_SUCCESS',
  HOLD_OR_RESERVE_FAILURE: 'HOLD_OR_RESERVE_FAILURE',
  HOLD_OR_RESERVE_LOADER: 'HOLD_OR_RESERVE_LOADER',

  SET_TAB_INDEX_PM: 'SET_TAB_INDEX_PM',

  SHOW_LOADER_RN_CASE: 'SHOW_LOADER_RN_CASE',

  SHOW_RN_CASE_SUCCESS: 'SHOW_RN_CASE_SUCCESS',
  SHOW_RN_CASE_FAILURE: 'SHOW_RN_CASE_FAILURE',

  OPEN_SWAP_CASE_TEAMS_SUCCESS: 'OPEN_SWAP_CASE_TEAMS_SUCCESS',
  OPEN_SWAP_CASE_TEAMS_FAILURE: 'OPEN_SWAP_CASE_TEAMS_FAILURE',
  OPEN_SWAP_CASE_TEAMS_LOADER: 'OPEN_SWAP_CASE_TEAMS_LOADER',

  GET_fORM_BASICDATA_SUCCESS: 'GET_fORM_BASICDATA_SUCCESS',
  GET_fORM_BASICDATA_FAILURE: 'GET_fORM_BASICDATA_FAILURE',

  SEARCH_EMP_PM_SUCCESS: 'SEARCH_EMP_PM_SUCCESS',
  SEARCH_EMP_PM_FAILURE: 'SEARCH_EMP_PM_FAILURE',
  SEARCH_EMP_PM_LOADER: 'SEARCH_EMP_PM_LOADER',

  CLEAR_EMP_LIST: 'CLEAR_EMP_LIST',
};
